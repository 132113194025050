import React, { useState } from 'react';
import { fetchOrderDetails } from './Query'
import './Home.css'
import logo from '../logo.png'

const OrderTracking = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  const handleTrackingSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const data = await fetchOrderDetails(trackingNumber);

    if (data) {
      setOrderDetails(data);
    } else {
      setError("No order details found. Please check the tracking number.");
    }
  };

  // Helper function to format order status
const formatStatus = (status) => {
  return status
    .replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

// Helper function to format time 
const formatDateToPST = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
};




  return (
    <div className='page-container'>
    
    <div className='logo-container'>
      <img src={logo} alt='Zipbox' className='logo'/> 
    </div>

    <div className='fullform'>
      
      <h1>Track Your Order</h1>
      <div className='form'>
      <form onSubmit={handleTrackingSubmit}>
     
        <input className='inputform'
          type="text"
          placeholder="Enter Tracking Number"
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
        />
        
      <button type="submit" className='button1'>Track Order</button>
        
      </form>
     
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {orderDetails && (
        <div>
          
          <div className='tracking-number'>
          <h2>Tracking Number:</h2>
          <p>{orderDetails.trackingNumber}</p>
          </div>
         
          <div className='order-details'>
          <h2>Order Details :</h2>
          </div>
          {orderDetails.trackingPoint.map((point, index) => (
          
            <div key={index} className='tracking-points' >
              
              <p ><strong> Delivery Location:</strong> {point.deliveryLocation}</p>
              <p><strong> Estimated Arrival Date:</strong> {formatDateToPST(orderDetails.trackingPoint[0].arrivalDate)}</p>
              <p><strong>Status:</strong> {formatStatus(point.orderStatus)}</p>
              <p><strong className='time-stamp'>Tracking Update:</strong> {formatDateToPST(orderDetails.trackingPoint[0].trackingUpdate)}</p>

            </div>
          ))}
        </div>
      )}
    </div>
    </div>
  );
};

export default OrderTracking;








