
import Homepage from './Pages/Homepage';


 
const App = () => {
   return (
      <>
         <Homepage  />
         
      </>
   );
};
 
export default App; 